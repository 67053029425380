import {reset} from "ansi-colors";
import jQuery from 'jquery/dist/jquery';
import * as bootstrap from 'bootstrap'
import Swal from 'sweetalert2';

import DefaultController from "../../../defaultController";
import Utils from "../../../../utils";
import UtilsErp from "../../../../utils/erp";
import UtilsEntity from "../../../../utils/entity";

export default class ErpBookingsList extends DefaultController {
    tableElem: any;
    serialRequired = false
    subEntities = [{name: "#", input_type: "counter"}, {name: "serial", input_type: "text", auto_propagate: false}]

    async init() {
        this.entity = "erp/products"
        this.tableElem = document.querySelector("#booking_add_table_body") as HTMLElement;
        await super.init();
        await this.getSubentities();

        this.tableElem.appendChild(this.loadTable());
        this.tableElem.appendChild(this.loadChildTable());
    }

    getEntityData(elem: any) {
        return {}
    }

    async getSubentities() {
        const r = await UtilsEntity.getAll("product_attributes");
        if (r.status === 200) {
            this.subEntities = this.subEntities.concat(r.data)
        }
    }

    bindListeners() {
        jQuery(document).ready(function() {
            jQuery(window).keydown(function(event){
                if(event.keyCode == 13) {
                    event.preventDefault();
                    const target = event.target;
                    console.log(target)
                    if (target && target.getAttribute("data-sub-entity")) {
                        const entity = target.getAttribute("data-sub-entity")
                        const tr = target.closest("tr");
                        const additionalRow = tr.nextElementSibling;
                        if (additionalRow) {
                            const nextEntity = additionalRow.querySelector(`*[data-sub-entity='${entity}']`)
                            if (nextEntity) {
                                nextEntity.focus();
                                nextEntity.select();
                            }
                        }

                    }
                    return false;
                }
            });
        });
        document.querySelector("#booking_add_row")?.addEventListener("click", (e) => {
            e.preventDefault();
            this.tableElem.appendChild(this.loadTable());
            this.tableElem.appendChild(this.loadChildTable());
        });

        document.querySelector("#booking_add_form")?.addEventListener("submit", async (e) => {
            e.preventDefault();
            const form = document.querySelector("#booking_add_form") as HTMLFormElement;
            const valid = form.checkValidity();
            let failed = null;
            if (valid) {
                const tracking_number = document.querySelector("#booking_add_delivery_code") as HTMLInputElement;
                const order_number = document.querySelector("#booking_add_order_number") as HTMLInputElement;
                const external_order_number = document.querySelector("#booking_add_external_order_number") as HTMLInputElement;

                const data = {
                    customer: document.querySelector("#booking_add_customer option:checked")?.value,
                    tracking_number: tracking_number ? tracking_number.value : "",
                    tracking_company: document.querySelector("#booking_add_delivery_company option:checked")?.value,
                    order_number: order_number ? order_number.value: "",
                    external_order_number: external_order_number ? external_order_number.value: "",
                    products: []
                };
                this.tableElem.querySelectorAll("tbody tr:not(.extra-tr, .extra-row)").forEach((row: any) => {
                    const newData: any = {payload: []};
                    ['expand', 'product', 'quantity', 'stored_at', 'warehouse', 'warehouse_location', 'warehouse_location_pallet'].forEach((entity: any) => {
                        let value: any = '';
                        if (entity === 'quantity' || entity === 'stored_at') {
                            value = row.querySelector(`*[data-field='${entity}']`)?.value;
                        } else {
                            value = row.querySelector(`*[data-field='${entity}'] option:checked`)?.value;
                        }
                        if (entity === "stored_at") {
                            value = `${value}${new Date().toString().match(/([-\+][0-9]+)\s/)[1]}`;
                        }
                        newData[entity] = value;
                    });
                    newData["serials"] = []
                    const additionalRow = row.nextElementSibling;
                    additionalRow.querySelectorAll("table tbody tr.extra-row")?.forEach((extraRow: any) => {
                        console.log("extraRow", extraRow)
                        const payload: any = {}
                        extraRow.querySelectorAll("td").forEach((subentityTd: any ) => {
                            const input = subentityTd.querySelector("input, select");
                            if (input) {
                                const name = input.getAttribute("data-sub-entity")
                                if (name === "condition") {
                                    const value = input.querySelector("option:checked")?.value;
                                    payload[name] = value.trim();
                                }else if (name !== "quantity") {
                                    const value = input.value;
                                    if (name === "serial") {
                                        newData['serials'].push(value.trim());
                                    } else {
                                        payload[name] = value.trim();
                                    }
                                }
                            }
                        })
                        if (Object.keys(payload).length > 0) {
                            newData.payload.push(payload)
                        }
                    });
                    data.products.push(newData);
                });
                if (failed) {
                    this.toastr.error(`${failed}`, `${Utils.translate('generic.failed')}`)
                } else {
                    await Utils.showLoader();
                    console.log(data);
                    await UtilsErp.addProducts(data)

                    this.tableElem.innerHTML = "";
                    this.tableElem.appendChild(this.loadTable());
                    this.tableElem.appendChild(this.loadChildTable());
                    tracking_number.value = "";
                    order_number.value = "";
                    external_order_number.value = "";
                    this.toastr.success(`${Utils.translate('erp.product.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)

                    await Utils.hideLoader();
                }

            }
        });
        jQuery(this.tableElem).delegate(".delete-record", "click", (e: any) => {
            e.preventDefault();
            this.tableElem.removeChild(e.target.closest("tr"));
        })
        jQuery(this.tableElem).delegate(".row-toggler", "click", (e: any) => {
            e.preventDefault();
            const target = e.currentTarget;
            const tr = target.closest("tr");
            const extraTr = tr.nextElementSibling;
            if (target.querySelector("i.ti")?.classList.contains("ti-caret-down")) {
                target.querySelector("i.ti")?.classList.remove("ti-caret-down");
                target.querySelector("i.ti")?.classList.add("ti-caret-up");
                extraTr.classList.remove("d-none");
            } else {
                target.querySelector("i.ti")?.classList.remove("ti-caret-up");
                target.querySelector("i.ti")?.classList.add("ti-caret-down");
                extraTr.classList.add("d-none");
            }
        })

        jQuery(this.tableElem).delegate("input[data-field='quantity']", "keyup change", (e: any) => {
            const target = e.target;
            const value = parseInt(target.value);
            const tr = target.closest("tr");
            const extraTr = tr.nextElementSibling;
            const table = extraTr.querySelector("table");
            const tbody = table?.querySelector("tbody")
            if (table && tbody) {
                if (tbody.childNodes.length === value) {

                }else if (tbody.childNodes.length < value) {
                    const currentLength = tbody.childNodes.length
                    const diff = value - tbody.childNodes.length;
                    for(let i= 0;i < diff;i++) {
                        table.querySelector("tbody")?.appendChild(this.generateExtraRow(currentLength +i +1));
                    }
                } else {
                    const diff =  tbody.childNodes.length - value;
                    for(let i= 0;i < diff;i++) {
                        const rowCount = table.rows.length;
                        table.deleteRow(rowCount -1);
                    }
                }
            }
        })

    }

    generateExtraRow(count: number) {
        const tr = document.createElement("tr");
        tr.classList.add("extra-row")
        this.subEntities.forEach((entity: any) => {
            const td = document.createElement("td");
            let input: HTMLInputElement | HTMLSelectElement | HTMLElement | null = null;
            if (entity.input_type === "select") {
                input = document.createElement("select")
                input.classList.add("form-select")
                input.setAttribute("data-sub-entity", entity.name);
                let html = "";
                entity.options.forEach((elem: any) => {
                    html += `<option value='${elem}'>${elem}</option>`
                })
                input.innerHTML = html;
            } else if(entity.input_type === "counter") {
                input = document.createElement("span")
                input.classList.add("d-flex");
                input.innerHTML = `<span>#${count}</span><button class="btn btn-xs btn-secondary clear_payload d-none"><i class="ti ti-trash"></i> </button>`
            } else if(entity.input_type === "date") {
                input = document.createElement("input")
                input.type = "date";
                input.classList.add("form-control")
                input.setAttribute("data-sub-entity", entity.name);
            } else {
                input = document.createElement("input")
                input.type = "text";
                input.classList.add("form-control")
                input.setAttribute("data-sub-entity", entity.name);
            }
            if (entity.name === "serial") {
                input.addEventListener("change", async (e) => {
                    if ("value" in input) {
                        const val = input.value;
                        const tr = input.closest("tr") as HTMLTableRowElement;
                        // @ts-ignore
                        const productSelect = tr.closest("tr.extra-tr").previousElementSibling.querySelector("select[data-field='product'] option:checked")
                        if (productSelect) {
                            const productId = productSelect.value
                            const customerId = document.querySelector("#booking_add_customer option:checked")?.value
                            if (customerId) {
                                const r = await UtilsErp.checkSerial(customerId, productId, val);
                                const inStockData = r.data.filter((d: any) => {
                                    return d.stock > 0
                                })
                                if (inStockData.length > 0) {
                                    this.toastr.error(`Serial ${val} is already used!`, `${Utils.translate('generic.failed')}`)
                                    input.style.border = "1px solid red";
                                } else {
                                    const d = r.data[0]
                                    if (d) {
                                        const payload = d.payload;
                                        let loadedCounter = 0;
                                        (tr.querySelectorAll("input[data-sub-entity]") as NodeListOf<HTMLInputElement>).forEach((subInput: HTMLInputElement) => {
                                            const entity = subInput.getAttribute("data-sub-entity");
                                            if (entity && payload[entity]) {
                                                loadedCounter++;
                                                subInput.value = payload[entity]
                                            }
                                        })
                                        if (loadedCounter > 0) {
                                            this.toastr.success(`${loadedCounter} elements loaded!`, `${Utils.translate('generic.success')}`)
                                        }
                                    }
                                    input.style.border = "";
                                }
                            }
                        }
                    }
                });
                if(this.serialRequired) {
                    // @ts-ignore
                    input.required = true;
                }
            }
            if (entity.auto_propagate === true) {
                input.addEventListener("change", (e) => {
                    if (input) {
                        const tr = input.closest("tr") as HTMLTableRowElement;
                        const tbody = input.closest("tbody") as HTMLElement;
                        let found = false;
                        console.log("change", tr)
                        Array.from(tbody.querySelectorAll("tr")).forEach((elem: any) => {
                            if (found === true) {
                                const child = elem.querySelector(`*[data-sub-entity='${entity.name}']`) as HTMLInputElement
                                if (child) {
                                    child.value = input.value
                                }
                            }
                            if (tr === elem) {
                                found = true
                            }
                        })
                    }
                });
            }
            td.appendChild(input);
            tr.appendChild(td);
        });

        return tr;
    }

    loadChildTable() {
        const tr = document.createElement("tr");
        tr.classList.add("extra-tr");
        tr.classList.add("d-none");
        const td = document.createElement("td")
        td.classList.add("extra-td");
        td.colSpan = 9;

        let tableHtml = `<table class="table table-striped"><thead><tr class="extra-row">`;
        this.subEntities.forEach((subentity: any) => {
            tableHtml += `<th>${subentity.name}</th>`
        });
        tableHtml += `</tr></thead><tbody></tbody></table>`;
        td.innerHTML = tableHtml;
        tr.appendChild(td);
        return tr;
    }

    loadTable() {
        const tr = document.createElement("tr");
        ['expand', 'product', 'quantity', 'stored_at', 'warehouse', 'warehouse_location', 'warehouse_location_pallet'].forEach((entity: any) => {
            const td = document.createElement("td");
            let onCreateNewElement = (data: any, entity: any) => {return {}};
            if (entity === "expand") {
                const button = document.createElement("button");
                button.classList.add("btn");
                button.classList.add("btn-xs");
                button.classList.add("btn-primary");
                button.classList.add("row-toggler");
                button.innerHTML = "<i class='ti ti-caret-down'></i>"
                td.appendChild(button)
            } else if (entity !== 'quantity' && entity !== 'stored_at') {
                const select = this.generateSelect(entity)
                td.appendChild(select);
                const additionalParams = () => {
                    if (entity === "warehouse_location") {
                        const warehouse_id = (tr.querySelector(`*[data-field='warehouse'] option:checked`) as HTMLOptionElement).value;
                        return {warehouse_id: warehouse_id}
                    } else if (entity === "warehouse_location_pallet") {
                        const warehouse_location_id = (tr.querySelector(`*[data-field='warehouse_location'] option:checked`) as HTMLOptionElement).value;
                        return {warehouse_location_id: warehouse_location_id}
                    }
                    return {};
                };
                if (entity === 'warehouse_location' || entity === 'warehouse_location_pallet') {
                    select.disabled = true;
                }
                if (entity === 'warehouse_location_pallet') {
                    onCreateNewElement = (data: any, entity: string) => {
                        const warehouse_location_id = (tr.querySelector(`*[data-field='warehouse_location'] option:checked`) as HTMLOptionElement).value;
                        return {
                            warehouse_location_id: warehouse_location_id
                        }
                    }
                }
                jQuery(select).select2({
                    placeholder: "Choose option..",
                    tags: entity === 'warehouse_location_pallet',
                    createTag: (params: any) => {
                        if (entity !== 'warehouse_location_pallet') {
                            return null;
                        } else {
                            return {
                                id: params.term,
                                text: `[Neu]${params.term}`,
                                isNew : true
                            };
                        }
                    },
                    ajax: {
                        url: `/api/v1/erp/${entity}s`,
                        data: (params: any) => {
                            return {
                                search: params.term,
                                ...additionalParams()
                            }
                        },
                        processResults: (data: any) => {
                            const d = data.data;
                            return {
                                results: d.map((k: any) => {
                                    return {text: k.name, id: k.uuid, data: k}
                                })
                            }
                        }
                    }
                }).on("select2:select", async (e: any) => {
                    console.log(e.params.data);
                    if (e.params.data.isNew) {
                        const r = await UtilsEntity.upsert({
                            //@ts-ignore
                            name: e.params.data.id,
                            ...onCreateNewElement({name: e.params.data.id}, entity)
                        }, `erp/${entity}s`);
                        jQuery(select).find('[value="' + e.params.data.id + '"]').replaceWith('<option selected value="' + r.data.id + '">' + r.data.name + '</option>');

                    }

                    if (entity === "warehouse") {
                        jQuery(tr.querySelector(`*[data-field='warehouse_location']`)).removeAttr("disabled");
                        jQuery(tr.querySelector(`*[data-field='warehouse_location_pallet']`)).attr("disabled", "disabled");
                    } else if (entity === "warehouse_location") {
                        jQuery(tr.querySelector(`*[data-field='warehouse_location_pallet']`)).removeAttr("disabled");
                    }

                    if (!e.params || !e.params.data || !e.params.data.noUpdate) {
                        if (entity === "warehouse") {
                            jQuery(tr.querySelector(`*[data-field='warehouse_location']`)).val("").trigger("change");
                            jQuery(tr.querySelector(`*[data-field='warehouse_location_pallet']`)).val("").trigger("change");
                        } else if (entity === "warehouse_location") {
                            jQuery(tr.querySelector(`*[data-field='warehouse_location_pallet']`)).val("").trigger("change");
                        }
                    }
                    if (entity === "product") {
                        console.log("AAAAAA", e.params.data.data.customFields)
                        const requiresSerial = e.params.data.data.customFields?.erp_product_requires_serial
                        const elem = jQuery(tr.querySelector(`*[data-field='serials']`));
                        this.serialRequired = requiresSerial
                        if (elem) {
                            if (requiresSerial) {
                                elem.attr("required", true)
                            } else {
                                elem.removeAttr("required")
                            }
                        }
                    }
                });

                if (entity === 'warehouse_location' || entity === 'warehouse' || entity === 'product') {
                    select.required = true;
                }
                if (entity === 'warehouse_location' || entity === 'warehouse') {
                    const trs = this.tableElem.querySelectorAll("tr")
                    if (trs.length > 0) {
                        const lastTr = Array.from(trs).slice(-1)[0];
                        console.log(lastTr)
                        //@ts-ignore
                        const lastOption = (lastTr.querySelector(`*[data-field='${entity}'] option:checked`) as HTMLOptionElement);
                        if (lastOption) {
                            const $newOption = jQuery("<option selected='selected'></option>").val(lastOption.value).text(lastOption.text)
                            jQuery(select).append($newOption).trigger('change');
                            setTimeout(() => {
                                jQuery(select).trigger({
                                    type: 'select2:select',
                                    params: {
                                        data: {
                                            noUpdate: true
                                        }
                                    }
                                });
                            }, 50);
                            select.disabled = false;
                        }
                    }
                }
            } else {
                const input = this.generateInput(entity)
                if (entity === 'stored_at') {
                    input.type = "datetime-local";
                    input.value = Utils.toLocalISOString(new Date());
                    const trs = this.tableElem.querySelectorAll("tr")
                    if (trs.length > 0) {
                        const lastTr = Array.from(trs).slice(-1)[0];
                        console.log(lastTr)
                        //@ts-ignore
                        const lastOption = (lastTr.querySelector(`*[data-field='${entity}']`) as HTMLOptionElement);
                        if (lastOption) {
                            input.value = lastOption.value;
                        }
                    }
                }
                td.appendChild(input);
                if (entity === 'quantity') {
                    input.required = true;
                    input.min = "0";
                }
            }
            tr.appendChild(td);
        })

        const td = document.createElement("td");
        td.innerHTML = `<a href="#" class="text-body delete-record"> <i class="ti ti-trash ti-sm mx-2"></i> </a>`
        tr.appendChild(td)
        return tr;
    }

    generateInput(entity: any) {
        const id = Utils.makeId();
        const input = document.createElement("input");
        input.classList.add("form-control")
        input.setAttribute("data-field", entity)
        input.type = entity === "quantity" ? "number" : "text";
        return input;
    }

    generateSelect(entity: any) {
        const id = Utils.makeId();
        const select = document.createElement("select");
        select.id = id;
        select.setAttribute("data-field", entity)
        select.classList.add("form-select");

        return select
    }
}